import { Link } from "gatsby"
import React, { useState, useRef } from "react"
import H3 from "../../core/H3"
import { sortData } from "../../../util/helper"

export const WorkCardBlock = ({ WorkCard }) => {
  const { projects, position } = WorkCard

  const [m, setM] = useState(null)
  const [tag, setTag] = useState([])
  const [reset, setReset] = useState(false)
  const workCardRef = useRef(null)

  const gridSize = 2
  let className = "lg:px-10"
  if (position === "Center width") {
    className = "lg:pl-64 lg:pr-64"
  }
  if (position === "Full width") {
    className = "lg:pl-32 lg:pr-32"
  }

  // Collect Technology
  let tArr = []
  const tempArrT = projects && projects.map(e => e.projectTechnologyTag.nodes)
  tArr = [...tempArrT]
  tArr = tArr
    .flat()
    .filter((v, i, a) => a.findIndex(t => t.name === v.name) === i).sort(sortData)

  // Collect Country
  let cArr = []
  const tempArrC = projects && projects.map(e => e.projectCountryTag.nodes)
  cArr = [...tempArrC]
  cArr = cArr
    .flat()
    .filter((v, i, a) => a.findIndex(t => t.name === v.name) === i).sort(sortData)

  // Collect Industry
  let iArr = []
  const tempArrI = projects && projects.map(e => e.projectIndustryTag.nodes)
  iArr = [...tempArrI]
  iArr = iArr
    .flat()
    .filter((v, i, a) => a.findIndex(t => t.name === v.name) === i).sort(sortData)

  const handleTags = value => {
    const exist = tag.indexOf(value)
    if (exist === -1) {
      setTag([...tag, value])
    } else {
      tag.splice(exist, 1)
      setTag([...tag])
    }
    setReset(false)
    if (workCardRef.current) {
      workCardRef.current.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        const elementPosition = workCardRef.current.getBoundingClientRect().top + window.scrollY;
        const scrollOffset = 100; 
        window.scrollTo({
          top: elementPosition - scrollOffset,
          behavior: "smooth",
        });
      }, 0);
    }
  }

  const handleRemoveItem = value => {
    const exist = tag.indexOf(value)
    if (exist > -1) {
      tag.splice(exist, 1)
      setTag([...tag])
    }
    if (workCardRef.current) {
      workCardRef.current.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        const elementPosition = workCardRef.current.getBoundingClientRect().top + window.scrollY;
        const scrollOffset = 100; 
        window.scrollTo({
          top: elementPosition - scrollOffset,
          behavior: "smooth",
        });
      }, 0);
    }
  }
  const handleReset = () => {
    setTag([])
    setReset(true)
    if (workCardRef.current) {
      workCardRef.current.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        const elementPosition = workCardRef.current.getBoundingClientRect().top + window.scrollY;
        const scrollOffset = 100; 
        window.scrollTo({
          top: elementPosition - scrollOffset,
          behavior: "smooth",
        });
      }, 0);
    }
  }

  const Data = [
    {
      title: "Country",
      list: cArr,
    },
    {
      title: "Technology",
      list: tArr,
    },
    {
      title: "Industry",
      list: iArr,
    },
  ]

  let finalArr = []
  projects.map(
    e =>
      (e?.projectIndustryTag?.nodes.some(x => tag.includes(x.name)) ||
        e?.projectTechnologyTag?.nodes.some(x => tag.includes(x.name)) ||
        e?.projectCountryTag?.nodes.some(x => tag.includes(x.name))) &&
      finalArr.push(e)
  )
  finalArr = finalArr.length > 0 && reset === false ? finalArr : projects

  const MenuItem = ({ title, list }) => {
    return (
      <>
        <div data-aos="fade-up" data-aos-once="true"></div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div className="flex justify-center align-middle">
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            key={Math.random()}
            onMouseEnter={() => setM(list)}
            onTouchStart={() => setM(list)} // add for mobile touch
            //   activeStyle={{ borderBottom: "2px solid #335fff" }}
            className="cursor-pointer hover:border-blue-500 border-transparent border-b-2 md:px-3 md:py-5 px-6 py-4 lg:px-4 lg:py-5 group inline-flex items-center text-base leading-6 font-medium focus:outline-none text-blue-500 focus:text-blue-500 hover:text-blue-500"
          >
            <h2 dangerouslySetInnerHTML={{ __html: title }} />
          </div>
        </div>
      </>
    )
  }

  const MenuExpander = e => {
    return (
      <>
        <div data-aos="fade-up" data-aos-once="true"></div>
        {/* <div className="absolute w-full shadow z-0"> */}
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div key={Math.random()} className="relative mx-auto md:max-w-4xl flex justify-center w-full shadow z-10" onMouseLeave={() => setM("")}>
          <div className="rounded-b-lg z-20 bg-white p-4 grid md:grid-cols-3 grid-cols-2 md:gap-3 gap-1 shadow-xs filter-scroll overflow-y-auto no-scrollbar absolute w-full " >
            {e &&
              e?.map(t => (
                <div
                  key={Math.random()}
                  aria-hidden="true"
                  onClick={() => handleTags(t?.name)}
                  className={`md:p-2 p-1 flex items-start rounded-md cursor-pointer ${tag.indexOf(t?.name) === -1
                    ? "text-shark-400"
                    : "text-blue-500 bg-blue-200"
                    } hover:text-blue-500 hover:bg-blue-200`}
                >
                  <div className="md:mx-4 md:my-2 mx-1 my-1">
                    <p
                      className="text-base leading-6 font-medium flex justify-center align-middle "
                      dangerouslySetInnerHTML={{ __html: t?.name }}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
        {/* </div> */}
      </>
    )
  }

  return (
    <>
      <div data-aos="fade-up" data-aos-once="true"></div>
      <div
        className="bg-white py-16 px-2"
        onClick={() => setM("")}
        aria-hidden="true"
      >
        <H3
          title="You can also check our portfolio as per different Countries, Technologies and Industries"
          className="max-w-4xl text-center mx-auto text-shark-500"
        />
      </div>
         {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div className="w-full z-10 bg-white sticky top-16" onMouseLeave={() => setM("")}> {/* do top-28 in gitex and ces event else top-16*/}
        {/* <div className="border-t-2 border-b-2 w-full flex justify-center align-middle "> */}
        <div className=" w-full flex items-center justify-center align-middle border-t-2 border-b-2">
          {Data?.map(i => (
            <>
              <MenuItem {...i} key={Math.random()} />
            </>
          ))}
        </div>
        {/* </div> */}
        {m && MenuExpander(m)}
        {tag?.length > 0 && reset === false ? (
          // <div className="">
          <div className="max-w-screen-xl flex flex-wrap mx-auto justify-start align-middle p-2 w-full z-0 bg-white">
            {tag?.map(item => (
              <div
                key={Math.random()}
                target="_blank"
                rel="noreferrer"
                className="text-white bg-blue-500 inline-flex rounded px-2 py-1 m-1"
              >
                <span className="leading-6 text-sm ">{item}</span>
                <div
                  onClick={() => handleRemoveItem(item)}
                  id="video_close_btn"
                  aria-hidden="true"
                  className="ml-4 cursor-pointer"
                  style={{
                    fontFamily: "saileclight",
                    fontSize: "25px",
                    lineHeight: "25px",
                    transform: "rotate(45deg)",
                  }}
                >
                  +
                </div>
              </div>
            ))}

            <div
              onClick={() => handleReset()}
              aria-hidden="true"
              className="text-blue-500 border border-blue-500 inline-flex rounded px-2 py-1 cursor-pointer m-1"
            >
              <span className="leading-6 text-sm ">Reset</span>
            </div>
          </div>
          // </div>
        ) : (
          <></>
        )}
      </div>

      <div
        className="bg-transperent py-16"
        onClick={() => setM("")}
        aria-hidden="true"
        ref={workCardRef}
      >
        <div
          className={`lg:grid lg:grid-cols-${gridSize} lg:gap-10 ${className} max-w-lg mx-auto lg:max-w-none`}
        >
          {finalArr.map(
            ({
              title,
              projectFeatureTag,
              WorkCPT: {
                coverImage,
                tagline,
                textAlign,
                android,
                ios,
                caseStudy,
                website,
              },
            }) => (
              <div
                key={Math.random()}
                className="flex justify-center flex-col overflow-hidden relative lg:rounded md:rounded rounded-none"
              >
                <div className="flex-shrink-0">
                  <div className="h-full w-full bg-black lg:block z-10 hidden">
                    <img
                      className="w-full object-cover z-0"
                      style={{ maxHeight: "540px" }}
                      src={coverImage?.mediaItemUrl}
                      width={coverImage?.mediaDetails?.width}
                      height={coverImage?.mediaDetails?.height}
                      alt={
                        coverImage?.altText ? coverImage?.altText : "Portfolio"
                      }
                      // title={
                      //   coverImage?.altText ? coverImage?.altText : "Portfolio"
                      // }
                      loading="lazy"
                    />
                  </div>
                </div>
                <div
                  className={`flex lg:hidden items-center bg-black bg-opacity-50 lg:bg-transparent text-center lg:absolute mx-auto top-auto h-full w-full lg:w-1/2 px-0 py-4 lg:p-6 ${textAlign === "right" ? "right-0" : ""
                    } text-white`}
                  style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('${coverImage?.mediaItemUrl}')`,
                    backgroundSize: 'cover',
                    minHeight: "280px"
                  }}
                >
                  <div className="flex-1 px-2 lg:p-0">
                  <div>
                      {title && <H3 title={title} className="text-white" />}
                    </div>
                    <div
                      className="text-sm mt-2"
                      dangerouslySetInnerHTML={{ __html: tagline }}
                    />
                    <p className="mt-4 lg:mt-8">
                      {projectFeatureTag?.nodes?.map(({ name }) => (
                        <button
                          key={Math.random()}
                          className="rounded text-xs m-1 inline-flex items-center font-normal leading-sm px-4 py-2 bg-transparent  bg-white bg-opacity-10 text-white"
                        >
                          {name}
                        </button>
                      ))}
                    </p>
                    <div className="mt-3 flex justify-center flex-row">
                      {ios ? (
                        <div className="mt-2 mr-4">
                          <a
                            href={ios.url}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <img
                              src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/04/appstore.png"
                              alt="Apple"
                              // title="Apple"
                              className="h-5 w-5"
                              loading="lazy"
                            />
                          </a>
                        </div>
                      ) : null}

                      {android ? (
                        <div className="mt-2 mr-4">
                          <a
                            href={android.url}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <img
                              src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/04/playstore.png"
                              alt="Android"
                              // title="Android"
                              className="h-5 w-5"
                              loading="lazy"
                            />
                          </a>
                        </div>
                      ) : null}

                      {website ? (
                        <div className="mt-2 mr-4">
                          <a
                            href={website.url}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <img
                              src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/04/web.png"
                              alt="Web"
                              // title="Web"
                              className="h-5 w-5"
                              loading="lazy"
                            />
                          </a>
                        </div>
                      ) : null}

                      {caseStudy ? (
                        <div className="mt-2 mr-4">
                          <Link to={caseStudy.url} className="">
                            <img
                              src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/04/arrow.png"
                              alt="Full case study"
                              // title="Full case study"
                              className="h-5 w-5"
                              loading="lazy"
                            />
                          </Link>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div
                  className={`lg:flex items-center bg-black bg-opacity-50 hidden lg:bg-transparent text-center absolute mx-auto top-auto h-full w-full lg:w-1/2 px-0 py-4 lg:p-6 ${textAlign === "right" ? "right-0" : ""
                    } text-white`}
                >
                   <div className="flex-1 px-2 lg:p-0">
                   <div>
                      {title && <H3 title={title} className="text-white" />}
                    </div>
                    <div
                      className="text-sm mt-2"
                      dangerouslySetInnerHTML={{ __html: tagline }}
                    />
                    <p className="mt-4 lg:mt-8">
                      {projectFeatureTag?.nodes?.map(({ name }) => (
                        <button
                          key={Math.random()}
                          className="rounded text-xs m-1 inline-flex items-center font-normal leading-sm px-4 py-2 bg-transparent  bg-white bg-opacity-10 text-white"
                        >
                          {name}
                        </button>
                      ))}
                    </p>
                    {/* {(timeDuration || members) && <div className="my-4 flex flex-wrap justify-center flex-row gap-3">
                      {members && <div className="flex gap-1  items-center">
                        <img
                          src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/04/Team-member.webp"
                          alt="Full case study"
                          title="Full case study"
                          className="h-5 w-5"
                          loading="lazy"
                        />
                        <span className="text-white">{members} Members</span>
                      </div>}
                      {timeDuration && <div className="flex gap-1  items-center">
                        <img
                          src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/06/Project-duration.webp"
                          alt="Full case study"
                          title="Full case study"
                          className="h-5 w-5"
                          loading="lazy"
                        />
                        <span className="text-white">{timeDuration} </span>
                      </div>}
                    </div>} */}
                    <div className="mt-3 flex justify-center flex-row">
                      {ios ? (
                        <div className="mt-2 mr-4">
                          <a
                            href={ios.url}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <img
                              src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/04/appstore.png"
                              alt="Apple"
                              // title="Apple"
                              className="h-5 w-5"
                              loading="lazy"
                            />
                          </a>
                        </div>
                      ) : null}

                      {android ? (
                        <div className="mt-2 mr-4">
                          <a
                            href={android.url}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <img
                              src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/04/playstore.png"
                              alt="Android"
                              // title="Android"
                              className="h-5 w-5"
                              loading="lazy"
                            />
                          </a>
                        </div>
                      ) : null}

                      {website ? (
                        <div className="mt-2 mr-4">
                          <a
                            href={website.url}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <img
                              src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/04/web.png"
                              alt="Web"
                              // title="Web"
                              className="h-5 w-5"
                              loading="lazy"
                            />
                          </a>
                        </div>
                      ) : null}

                      {caseStudy ? (
                        <div className="mt-2 mr-4">
                          <Link to={caseStudy.url} className="">
                            <img
                              src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/04/arrow.png"
                              alt="Full case study"
                              // title="Full case study"
                              className="h-5 w-5"
                              loading="lazy"
                            />
                          </Link>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </>
  )
}

export default WorkCardBlock
